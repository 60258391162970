import React, { useState, useEffect, useCallback } from "react";

// react router
import { useParams, Redirect } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// antd
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

// color theme
import { colours } from "../constants/theme";

// chart.js
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

// api calls
import { useReferenceSpectrum, useSample } from "./../api";
import { useSampleEdit } from "./../api/posts";
import { useDefaultDeviceManufacturer } from "./../api";

// context calls
import { useParamsContext } from "./../contexts";
import { useReachbackContext } from "../contexts/ReachbackContext";

// components
import Footer from "./../components/Footer";
import Navbar from "./../components/Navbar";
import { CardA, CardC } from "./../components/ui/Cards";
import Notification from "./../components/ui/Notification";
import { ColourCircleC } from "./../components/ui/ColourCircle";
import { SequenceB, SequenceC } from "./../components/ui/Sequence";
import { SequenceRadioB } from "./../components/ui/Sequence";

// JavaScript Utility classes
import { includes, without } from "lodash";
import { getTestFindings } from "../helpers/samples";
import { postProcessSpectra } from "../api/sample";

export default function ViewSample() {
  const { id } = useParams();
  const { data: deviceManufacturer } = useDefaultDeviceManufacturer();
  const [findings, setFindings] = useState([]);

  // api
  const {
    data: sample,
    isErrorT,
    isErrorB,
    isSuccess,
    isLoading,
  } = useSample(id);

  const {
    mutateAsync,
    isSuccess: editSuccess,
    isErrorT: editFailedT,
    isErrorB: editFailedB,
  } = useSampleEdit(id);

  //hook for fetching the reference spectra
  const {
    results: refData,
    isSuccess: isRefSuccess = false,
    isError: isRefError = false,
    isLoading: isRefLoading,
  } = useReferenceSpectrum(findings);

  // context
  const { getAdverseList, getColour, getTexture } = useParamsContext();
  const { submittedErrors } = useReachbackContext();

  // states
  const [isReachback, setIsReachback] = useState(false);
  const [adverseSelection, setAdverseSelection] = useState([]);
  const [prevSelections, setPrevSelections] = useState([]);
  const [testedFlag, setTestedFlag] = useState("");
  const [colour, setColour] = useState({});
  const [texture, setTexture] = useState({});
  const [submit, setSubmit] = useState("");
  const [description, setDescription] = useState("");
  const [spectraChartData, setSpectraChartData] = useState({});
  const [spectraChartDataLoading, setSpectraChartDataLoading] = useState(true);
  const [isAdverseReaction, setIsAdverseReaction] = useState(false);
  const [isReachbackEmailSent, setIsReachbackEmailSent] = useState(true);
  const [emailData, setEmailData] = useState({
    deviceId: sample?.deviceId,
    processedDate: sample?.processedDate,
    sampleId: sample?.sampleId,
    notes: description,
  });
  const [isTraceScan, setIsTraceScan] = useState(false);
  const [isBulkScan, setIsBulkScan] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    // console.log("isReachback", isReachback);
    // console.log("isReachbackEmailSent", isReachbackEmailSent);

    //if reachback is true and email has not been sent, the sendEmail function is called and the JSON file is updated
    const checkReachbackEmailSent = async () => {
      if (isReachback && !isReachbackEmailSent) {
        // Update the email data with relevant information
        setEmailData({
          customerId: sample.customerId,
          deviceId: sample.deviceId,
          processedDate: sample.processedDate,
          sampleId: sample.sampleId,
          isErrorT: sample.isErrorT,
          isErrorB: sample.isErrorB,
          notes: description, // Use the description or any other relevant information
        });

        setIsReachbackEmailSent(true);
        await sendEmail();

        await mutateAsync({
          id: id,
          field: "isReachbackEmailSent",
          value: true,
        });

        if (!sample.reachback) {
          //reachback boolean represents if reachback has been requested or not
          const data = {
            id: id,
            field: "reachback",
            value: true,
          };
          await mutateAsync(data);
        }

        if (
          sample.reachbackComplete == "N/A" ||
          sample.reachbackComplete == "Complete"
        ) {
          console.log("reachbackComplete:", sample.reachbackComplete);
          const data = {
            id: id,
            field: "reachbackComplete",
            value: "inProgress",
          };
          await mutateAsync(data);
        }
      }
    };

    checkReachbackEmailSent();
  }, [isReachback, isReachbackEmailSent]);

  //set email data on sample or description change
  useEffect(() => {
    console.log(sample);
    setEmailData({
      customerId: sample?.customerId,
      deviceId: sample?.deviceId,
      processedDate: sample?.processedDate,
      sampleId: sample?.sampleId,
      isErrorT: sample?.isErrorT,
      isErrorB: sample?.isErrorB,
      notes: description,
    });
  }, [sample, description]);

  //when sample is changed, set state variables with sample data.
  useEffect(() => {
    if (!sample) {
      console.log("waiting for sample data");
      return;
    }

    if (sample.reachback) {
      setIsReachback(sample.reachback);
    }

    setIsReachbackEmailSent(sample.isReachbackEmailSent);
    setDescription(sample.description);

    setColour(getColour(sample.colourId));

    if (sample.texture) {
      setTexture({
        ...getTexture(sample.texture?.id),
        description: sample.texture?.description,
      });
    }

    if (sample.categories.length !== 0) {
      setIsAdverseReaction(true);
      setPrevSelections(
        sample.categories.map((item) => {
          return item.categoryId;
        })
      );
    } else {
      setIsAdverseReaction(false);
      setPrevSelections([]);
    }

    if (sample.tested) {
      console.log("sample.tested:", sample.tested);
      setTestedFlag(sample.tested);
    }

    //set findings list which is used to fetch reference spectra
    let findingsList = sample.tests.flatMap((test) => {
      return test.findings.map((finding) => {
        return { collection: test.scanType, analyte: finding.substance };
      });
    });
    setFindings(findingsList);
  }, [sample, getColour]);

  function addAdverse(adverse) {
    if (!includes(adverseSelection, adverse.id)) {
      setAdverseSelection((oldArray) => [...oldArray, adverse.id]);
    }
  }

  function removeAdverse(adverse) {
    if (includes(prevSelections, adverse)) {
      setPrevSelections(prevSelections.filter((item) => item !== adverse));
    }
    if (includes(adverseSelection, adverse)) {
      setAdverseSelection(without(adverseSelection, adverse));
    }
  }

  // Function to handle the selection of "Yes" or "No" for adverse reactions
  const handleSelectAdverseReaction = (e) => {
    const value = e.target.value;
    setAdverseSelection([]);
    if (value === "Yes") {
      setIsAdverseReaction(true);
    } else {
      setIsAdverseReaction(false);
      setPrevSelections([]);
    }
  };

  // GRAPH
  // graph view component
  const { Panel } = Collapse;
  const graphOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Raman shift (cm^-1)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Intenisty (a.u.)",
        },
      },
    },
    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      legend: {
        align: "end",
        display: true,
        labels: {
          boxWidth: 10,
        },
      },
    },
  };

  // returns trace scans if they exist, otherwise returns []
  function traceScans(sample) {
    const traceScansData = sample.tests.filter(
      (test) =>
        test.scanType.toLowerCase() === "trace-burst" ||
        test.scanType.toLowerCase() === "bulk-burst"
    );
    return traceScansData;
  }

  // returns true if this sample HAS trace scans and comes from a wasatch
  const needsGraph = useCallback(() => {
    if (sample) {
      if (traceScans(sample).length > 0 && deviceManufacturer === "wasatch") {
        return true;
      }
    }
    return false;
  }, [sample, deviceManufacturer]);

  // Spectra chart data
  useEffect(() => {
    if (needsGraph() && !isRefLoading && (isRefSuccess || isRefError)) {
      const traceScansData = sample.tests.filter(
        (test) => test.scanType.toLowerCase() === "trace-burst"
      );
      const bulkScansData = sample.tests.filter(
        (test) => test.scanType.toLowerCase() === "bulk-burst"
      );
      let traceChartData = getGraphData(traceScansData[0]); // Pass the entire object
      let bulkChartData = getGraphData(bulkScansData[0]); // Pass the entire object

      if (!isRefError && refData.length > 0) {
        //create new array of reference spectrum data with just the necessary info
        const mappedRefData = refData.map((item) => {
          return {
            data: item.data.data,
            label: item.data.label,
            scanType: item.data.scanType,
          };
        });

        //separate trace and bulk reference spectrum data
        let traceRefData = mappedRefData.filter(
          (item) => item.scanType === "trace-burst"
        );
        let bulkRefData = mappedRefData.filter(
          (item) => item.scanType === "bulk-burst"
        );

        const palette = [
          "#56B4E9",
          "#E69F00",
          "#009E73",
          "#F0E442",
          "#0072B2",
          "#D55E00",
          "#CC79A7",
        ];

        //add reference spectrum data to chart data objects
        traceRefData = traceRefData.map((item, index) => {
          return {
            data: item.data,
            label: item.label,
            fill: false,
            borderColor: palette[index],
            backgroundColor: palette[index],
            hidden: true,
            pointRadius: 1.5,
          };
        });
        bulkRefData = bulkRefData.map((item, index) => {
          return {
            data: item.data,
            label: item.label,
            fill: false,
            borderColor: palette[index],
            backgroundColor: palette[index],
            hidden: true,
            pointRadius: 1.5,
          };
        });

        traceRefData.forEach((item) => traceChartData.datasets.push(item));
        bulkRefData.forEach((item) => bulkChartData.datasets.push(item));
      }

      if (traceChartData.datasets[0].data.length !== 0) {
        setIsTraceScan(true);
      } else {
        setIsTraceScan(false);
      }
      if (bulkChartData.datasets[0].data.length !== 0) {
        setIsBulkScan(true);
      } else {
        setIsBulkScan(false);
      }

      setSpectraChartData({
        trace: traceChartData,
        bulk: bulkChartData,
      });

      setSpectraChartDataLoading(false);
    } else {
      // No graph needed, set the chart data to an empty state or some default data.
      setSpectraChartData({
        trace: getGraphData(null),
        bulk: getGraphData(null),
      });
      setSpectraChartDataLoading(false);
    }
  }, [sample, isRefSuccess, isRefError, needsGraph]);

  // generate graph data
  // X labels: wavenumber
  // Y data: scan data
  function getGraphData(sample) {
    if (!sample) {
      console.log("No scan data:", sample);
      return {
        labels: ["None"],
        datasets: [
          {
            label: "None",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [],
          },
        ],
      };
    }

    const data = {};
    const palette = Object.values(colours);
    const xValues = sample.wavenumber_plot;
    // const xValues = sample[0].wavenumber_plot;

    data.labels = xValues;

    const dataset = {
      data: sample.processed_data_plot,
      label: "Scan",
      fill: false,
      borderColor: "#000000",
      backgroundColor: "#000000",
      pointRadius: 1.5,
    };

    data.datasets = [dataset];

    return data;
  }

  const graphHeader = () => (
    <span
      className='card-title text-primary'
      style={{ fontWeight: "500", fontSize: "130%" }}
    >
      Trace Scan Spectra
    </span>
  );

  const graphHeader2 = () => (
    <span
      className='card-title text-primary'
      style={{ fontWeight: "500", fontSize: "130%" }}
    >
      Bulk Scan Spectra
    </span>
  );

  //TODO: check if this code can be deleted

  // useEffect(() => {
  //   if (isSuccess && submittedErrors !== null) {
  //     setPrevSelections(
  //       sample.categories.map((item) => {
  //         return item.categoryId;
  //       })
  //     );
  //     setColour(getColour(sample.colourId));
  //     setDescription(sample.description);
  //     if (sample.tested) {
  //       console.log("sample.tested:", sample.tested);
  //       setTestedFlag(sample.tested);
  //     }

  //     if (submittedErrors === "reachback") {
  //       // Set the reachback state to true
  //       setIsReachback(true);
  //     } else {
  //       // For other conditions, reset the reachback state
  //       setIsReachback(false);
  //     }
  //   }
  // }, [isSuccess, sample, getColour, submittedErrors]);

  console.log("submittedErrors:", submittedErrors); // Log the value

  // reset submit after notification
  useEffect(() => {
    let timer = setTimeout(() => setSubmit(""), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [submit]);

  const handleSelectFlag = (e) => {
    setTestedFlag(e.target.value);
  };

  //this function sends the email for reachback service
  const sendEmail = async () => {
    try {
      console.log("Sending email...");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subject: `Reachback: ${emailData.sampleId}, Customer ID: ${emailData.customerId} `,
            html: `
              <h1>Sample information:</h1>
              <p>Customer ID: ${emailData.customerId}</p>
              <p>Device ID: ${emailData.deviceId}</p>
              <p>Processed Date: ${emailData.processedDate}</p>
              <p>Sample ID: ${emailData.sampleId}</p>
              <p>Was there an error with your trace scan? ${emailData.isErrorT}</p>
              <p>Was there an error with your bulk scan? ${emailData.isErrorB}</p>
              <p>Notes: ${emailData.notes}</p>
            `,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log(data.messageId);
        console.log("Email sent successfully!");
        setIsReachbackEmailSent(true);
        await mutateAsync({
          id: id,
          field: "isReachbackEmailSent",
          value: true,
        });
      } else {
        console.error("Error sending email:", data.error);
        console.error("Email sending failed!");
        setIsReachbackEmailSent(false);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      console.error("Email sending failed!");
      setIsReachbackEmailSent(false);
    }
  };

  async function handleSave() {
    const selected = [...new Set([...prevSelections, ...adverseSelection])];

    if (description !== sample.description) {
      await mutateAsync({ id: id, field: "description", value: description });
    }

    await mutateAsync({ id: id, field: "adverse", value: selected });

    await mutateAsync({ id: id, field: "tested", value: testedFlag });
    setIsSaved(true);
  }

  //updates submit state and error states when editSuccess or editFailedT/B is true
  useEffect(() => {
    if (editSuccess) {
      setSubmit("success");
    }
    if (editFailedT) {
      setSubmit("error");
    }

    if (editFailedB) {
      setSubmit("error");
    }
  }, [editSuccess, editFailedT, editFailedB]);

  if (isErrorT || isErrorB) {
    return (
      <div>
        <Navbar signedIn={false} />
        <div className='bg-white p-5 m-5 y-5-auto'>
          <CardA title='Sample not found' />
        </div>
        <Footer />
      </div>
    );
  }

  if (isLoading === true || (isRefSuccess === false && isRefError === false)) {
    return (
      <div>
        <Navbar signedIn={false} />
        <div className='bg-white p-5 m-5 y-5-auto'>
          <div
            className='alert mx-3'
            style={{ backgroundColor: "#7ECBF7", fontWeight: "bold" }}
            role='alert'
          >
            Fetching sample ...
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Navbar signedIn={false} />
      <div className='bg-white p-5 m-5 y-5-auto'>
        {isSuccess && (isRefSuccess || isRefError) && (
          <div className='w-75 mx-auto'>
            <CardA title='Results Summary'>
              <div className='ms-4 my-4'>
                <div>
                  <div className='card'>
                    <div className='card-body py-3'>
                      <h5 className='card-title text-primary'>Sample ID</h5>
                      <p>{sample.sampleId}</p>
                    </div>
                  </div>
                  <br />
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title text-primary'>
                        Sample Information
                      </h5>
                      <div className='row'>
                        <div className='col-4'>
                          <h6>Presumed Substance</h6>
                          <p>
                            {sample.presumedSubstance === "Other" &&
                            sample.presumedSubDescription
                              ? `${sample.presumedSubstance} - ${sample.presumedSubDescription}`
                              : sample.presumedSubstance}
                          </p>
                        </div>
                        <div className='col-4'>
                          <h6>Colour</h6>
                          <div>{colour && <ColourCircleC data={colour} />}</div>
                        </div>
                        <div className='col-4'>
                          <h6>Sample Texture</h6>
                          <div>{<CardC data={texture} />}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* SAMPLE RESULTS */}
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title text-primary'>
                        Sample Results
                      </h5>
                      <div className='row'>
                        <div className='col-6'>
                          <h6>Bulk Scan(s)</h6>
                          <div className='row'>
                            {sample.tests
                              .filter((item) => item.rb_findings?.length > 0)
                              .filter(
                                (item) =>
                                  item.scanType.toLowerCase() === "bulk-burst"
                              ).length > 0 ? (
                              sample.tests
                                .filter(
                                  (item) =>
                                    item.rb_findings?.length > 0 &&
                                    item.scanType.toLowerCase() === "bulk-burst"
                                )
                                .map((item, index) => (
                                  <div key={index} className='col-lg-12 mb-2'>
                                    <SequenceB
                                      sequenceId={
                                        item.sequenceId || item.sampleId
                                      }
                                      findings={
                                        item.rb_findings?.map(
                                          (finding) => finding.substance
                                          // )
                                        ) || ["Inconclusive"]
                                      }
                                      pureSubstance={item.rb_findings?.map(
                                        (finding) => finding.Pure_substance
                                      )}
                                    />
                                  </div>
                                ))
                            ) : sample.tests
                                .filter((item) => item.findings.length > 0)
                                .filter(
                                  (item) =>
                                    item.scanType.toLowerCase() === "bulk-burst"
                                ).length > 0 ? (
                              sample.tests
                                .filter(
                                  (item) =>
                                    item.findings.length > 0 &&
                                    item.scanType.toLowerCase() === "bulk-burst"
                                )
                                .map((item, index) => (
                                  <div key={index} className='col-lg-12 mb-2'>
                                    <SequenceB
                                      sequenceId={
                                        item.sequenceId || item.sampleId
                                      }
                                      findings={
                                        item.findings.map(
                                          (finding) => finding.substance
                                          // )
                                        ) || ["Inconclusive"]
                                      }
                                      pureSubstance={item.findings.map(
                                        (finding) => finding.Pure_substance
                                      )}
                                    />
                                  </div>
                                ))
                            ) : (
                              <div className='col-6'>
                                <p className='card-text'>Skipped</p>
                              </div>
                            )}
                          </div>
                          {sample.tests
                            .filter((item) => item.findings.length > 0)
                            .filter(
                              (item) =>
                                item.scanType.toLowerCase() === "bulk-burst"
                            ).length > 0 &&
                            sample.isErrorB &&
                            (sample.reachbackComplete === "inProgress" ||
                              sample.reachback === true) && (
                              <>
                                <div className='d-flex align-items-center mb-2'>
                                  <i
                                    className='bi bi-exclamation-triangle-fill'
                                    style={{
                                      fontSize: "large",
                                      color: "#AE0000",
                                      marginRight: "10px",
                                      marginBottom: "2px",
                                    }}
                                  ></i>
                                  <span
                                    style={{
                                      color: "#AE0000",
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Displayed results are currently undergoing
                                    expert review and should be interpreted
                                    cautiously.
                                  </span>
                                </div>
                                <div className='d-flex align-items-center'>
                                  <i
                                    className='bi bi-clock-fill'
                                    style={{
                                      fontSize: "large",
                                      color: "#F08021",
                                      marginRight: "10px",
                                      marginBottom: "2px",
                                    }}
                                  ></i>
                                  <span
                                    style={{
                                      color: "#F08021",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Reachback Submitted - We'll be in touch
                                    shortly!
                                  </span>
                                </div>
                              </>
                            )}
                        </div>
                        <div className='col-6'>
                          <h6>Trace Scan(s)</h6>
                          <div className='row'>
                            {
                              //check if the sample has trace scans and if the scan has reachback findings
                              sample.tests
                                .filter(
                                  (item) =>
                                    item.scanType.toLowerCase() ===
                                    "trace-burst"
                                )
                                .filter((item) => item.rb_findings?.length > 0)
                                .length > 0 ? (
                                //if the sample has trace scans and the scan has reachback findings, display the sequence
                                sample.tests
                                  .filter(
                                    (item) => item.rb_findings?.length > 0
                                  )
                                  .filter(
                                    (item) =>
                                      item.scanType.toLowerCase() ===
                                      "trace-burst"
                                  )
                                  .map((item, index) => (
                                    <div key={index} className='col-lg-12 mb-2'>
                                      <SequenceB
                                        sequenceId={
                                          item.sequenceId || item.sampleId
                                        }
                                        findings={
                                          item.rb_findings?.map(
                                            (finding) => finding.substance
                                          )
                                          // ) || ["Inconclusive"]
                                        }
                                      />
                                    </div>
                                  ))
                              ) : sample.tests //else if the sample has trace scans but no reachback findings, display the original findings
                                  .filter(
                                    (item) =>
                                      item.scanType.toLowerCase() ===
                                      "trace-burst"
                                  )
                                  .filter((item) => item.findings.length > 0)
                                  .length > 0 ? (
                                sample.tests
                                  .filter((item) => item.findings.length > 0)
                                  .filter(
                                    (item) =>
                                      item.scanType.toLowerCase() ===
                                      "trace-burst"
                                  )
                                  .map((item, index) => (
                                    <div key={index} className='col-lg-12 mb-2'>
                                      <SequenceB
                                        sequenceId={
                                          item.sequenceId || item.sampleId
                                        }
                                        findings={
                                          item.findings.map(
                                            (finding) => finding.substance
                                          )
                                          // ) || ["Inconclusive"]
                                        }
                                      />
                                    </div>
                                  ))
                              ) : (
                                //if the sample has no trace scans or no findings, display "Skipped"
                                <div className='col-6'>
                                  <p className='card-text'>Skipped</p>
                                </div>
                              )
                            }
                          </div>
                          {sample.tests
                            .filter((item) => item.findings.length > 0)
                            .filter(
                              (item) =>
                                item.scanType.toLowerCase() === "trace-burst"
                            ).length > 0 &&
                            sample.isErrorT &&
                            (sample.reachbackComplete === "inProgress" ||
                              sample.reachback === true) && (
                              <>
                                <div className='d-flex align-items-center mb-2'>
                                  <i
                                    className='bi bi-exclamation-triangle-fill'
                                    style={{
                                      fontSize: "large",
                                      color: "#AE0000",
                                      marginRight: "10px",
                                      marginBottom: "2px",
                                    }}
                                  ></i>
                                  <span
                                    style={{
                                      color: "#AE0000",
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Displayed results are currently undergoing
                                    expert review and should be interpreted
                                    cautiously.
                                  </span>
                                </div>
                                <div className='d-flex align-items-center'>
                                  <i
                                    className='bi bi-clock-fill'
                                    style={{
                                      fontSize: "large",
                                      color: "#F08021",
                                      marginRight: "10px",
                                      marginBottom: "2px",
                                    }}
                                  ></i>
                                  <span
                                    style={{
                                      color: "#F08021",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Reachback Submitted - We'll be in touch
                                    shortly!
                                  </span>
                                </div>
                              </>
                            )}
                        </div>

                        {sample.reachbackComplete === "Complete" && (
                          <>
                            <div className='d-flex align-items-center justify-content-center mt-2'>
                              <i
                                className='bi bi-check-circle-fill'
                                style={{
                                  fontSize: "large",
                                  color: "green",
                                  marginRight: "10px",
                                  marginBottom: "2px",
                                }}
                              ></i>
                              <span
                                style={{
                                  color: "black",
                                }}
                              >
                                Reachback completed
                              </span>
                            </div>
                          </>
                        )}

                        {/* TODO: Removed Noteworthy temporarily until it is revamped on the model function */}
                        {/* <div className="col-4">
                          <h6>Noteworthy</h6>

                          {sample.noteworthy ? (
                            sample.noteworthy.length === 0 ? (
                              "No"
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#FA8335"
                                      class="bi bi-exclamation-triangle-fill"
                                      viewBox="0 1 16 16"
                                    >
                                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg>
                                  </div>
                                  Yes
                                </div>
                              </>
                            )
                          ) : (
                            "No"
                          )}
                        </div> */}
                      </div>
                      {/* &&
                        spectraChartData.bulk.datasets[0].data.length!==0 */}
                      {isBulkScan && needsGraph() && (
                        <div className='row'>
                          <Collapse
                            expandIconPosition='start'
                            ghost={true}
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined
                                rotate={isActive ? 90 : 0}
                                style={{ color: colours.spectraBlue }}
                              />
                            )}
                          >
                            <Panel header={graphHeader2()} key='2'>
                              {spectraChartDataLoading ? (
                                <span>Loading...</span>
                              ) : (
                                <div>
                                  <p className='fst-italic'>
                                    Click on the legend item to display/disable
                                    the spectrum.
                                  </p>
                                  <Line
                                    data={spectraChartData.bulk}
                                    options={graphOptions}
                                  />
                                </div>
                              )}
                            </Panel>
                          </Collapse>
                        </div>
                      )}
                      {/* && */}
                      {/* spectraChartData.trace.datasets[0].data.length!==0 */}
                      {isTraceScan && needsGraph() && (
                        <div className='row'>
                          <Collapse
                            expandIconPosition='start'
                            ghost={true}
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined
                                rotate={isActive ? 90 : 0}
                                style={{ color: colours.spectraBlue }}
                              />
                            )}
                          >
                            <Panel header={graphHeader()} key='1'>
                              {spectraChartDataLoading ? (
                                <span>Loading...</span>
                              ) : (
                                <div>
                                  <p className='fst-italic'>
                                    Click on the legend item to display/disable
                                    the spectrum.
                                  </p>
                                  <Line
                                    data={spectraChartData.trace}
                                    options={graphOptions}
                                  />
                                </div>
                              )}
                            </Panel>
                          </Collapse>
                        </div>
                      )}

                      {((!sample.isErrorT && !sample.isErrorB) ||
                        sample.reachback == false) && (
                        <div className='d-flex flex-row justify-content-center align-items-center flex-wrap'>
                          <h6 className='col-auto mb-0 text-center me-2'>
                            Are you concerned about the results? Send for
                            Reachback Service.
                          </h6>
                          <div className='d-flex flex-column width-auto'>
                            <button
                              type='submit'
                              className={`btn btn-primary align-self-center `}
                              style={{ minWidth: "150px" }}
                              onClick={() => setIsReachback(true)}
                              disabled={isReachback}
                            >
                              {isReachback
                                ? "Already Submitted"
                                : "Submit for Reachback"}
                            </button>
                            {isReachback ? (
                              <p
                                className='align-self-center text-center'
                                style={{
                                  color: "black",
                                  fontStyle: "italic",
                                }}
                              >
                                Reachback Submitted - We'll be in touch shortly!
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title text-primary'>
                        Post-Consumption Information
                      </h5>
                      <div className='row'>
                        {/*//this field was not being saved to the database.*/}
                        {/* <div className="col-4">
                          <h6>Tested Pre-Use or Post-Use?</h6>
                          <div className="form-check">
                            <SequenceRadioB
                              id="testedOpt1"
                              value="Pre-use"
                              name="testedFlag"
                              checked={testedFlag === "Pre-use"}
                              onChange={handleSelectFlag}
                            />
                            <SequenceRadioB
                              id="testedOpt2"
                              value="Post-use"
                              name="testedFlag"
                              checked={testedFlag === "Post-use"}
                              onChange={handleSelectFlag}
                            />
                            <SequenceRadioB
                              id="testedOpt3"
                              value=""
                              name="testedFlag"
                              checked={testedFlag === ""}
                              onChange={handleSelectFlag}
                            />
                          </div>
                        </div> */}
                        <div className='col'>
                          {/* <div className="col-8"> */}
                          <h6>Adverse Reactions?</h6>
                          <div className='d-flex flex-wrap'>
                            <div className='form-check'>
                              {/* New radio buttons for "Yes" and "No" */}
                              <input
                                type='radio'
                                className='form-check-input'
                                id='adverseYes'
                                value='Yes'
                                name='adverseReaction'
                                checked={isAdverseReaction}
                                onChange={handleSelectAdverseReaction}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='adverseYes'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check ms-3'>
                              <input
                                type='radio'
                                className='form-check-input'
                                id='adverseNo'
                                value='No'
                                name='adverseReaction'
                                checked={!isAdverseReaction}
                                onChange={handleSelectAdverseReaction}
                              />
                              <label
                                className='form-check-label '
                                htmlFor='adverseNo'
                              >
                                No
                              </label>
                            </div>
                          </div>

                          {isAdverseReaction && (
                            // <div
                            //   className="col-8 mt-4"
                            //   style={{ marginLeft: "-15px" }}
                            // >
                            <div className='col'>
                              <h6>Required intervention</h6>
                              {getAdverseList()
                                ? getAdverseList().map((item, index) => {
                                    return (
                                      <SequenceC
                                        key={index}
                                        adverse={item}
                                        checked={includes(
                                          adverseSelection,
                                          item.id
                                        )}
                                        prevSelect={includes(
                                          prevSelections,
                                          item.id
                                        )}
                                        add={addAdverse}
                                        remove={removeAdverse}
                                      />
                                    );
                                  })
                                : "None"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title text-primary'>Notes</h5>
                      <div className='row'>
                        <div className='form-outline w-50 mb-4'>
                          <textarea
                            className='form-control'
                            rows='3'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{
                              border:
                                description !== sample.description
                                  ? "1px solid #007BFF"
                                  : "",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {submit === "success" && (
                <>
                  {console.log("submit success")}
                  {/* <Notification type='success' message='Saved!' duration={3} /> */}
                  {isSaved && <Redirect to='/recent' />}
                </>
              )}
              {submit === "error" && (
                <Notification
                  type='danger'
                  message='Something went wrong, please try again'
                  duration={3}
                />
              )}
              <div className='d-flex flex-row-reverse'>
                <button
                  className='btn btn-primary m-2'
                  onClick={() => handleSave()}
                >
                  Save
                </button>

                <Link className='btn btn-secondary m-2' to='/recent'>
                  Close
                </Link>
              </div>
            </CardA>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
