import React, { createContext, useContext } from "react";

import {
  useColours,
  useNewColours,
  useAdverse,
  useTextures,
} from "./../api/index";

import { find } from "lodash";

const ParamsContext = createContext();

export function ParamsProvider(props) {
  const {
    data: coloursList,
    isError: colourError,
    isSuccess: colourFetched,
  } = useNewColours();
  // const {
  //   data: coloursList,
  //   isError: colourError,
  //   isSuccess: colourFetched,
  // } = useColours();

  const {
    data: texturesList,
    isError: textureError,
    isSuccess: textureFetched,
  } = useTextures();

  const {
    data: adverseList,
    isError: adverseError,
    isSuccess: adverseFetched,
  } = useAdverse();

  function getColour(id) {
    if (colourFetched) {
      let colour;
      coloursList.map((item) => {
        item.colours.map((c) => {
          if (c.id === id) {
            colour = c;
            if (!colour.label) {
              colour["label"] = item.label;
            }
          }
        });
      });
      return colour;
    }
    if (colourError) {
      return { error: "Colours could not be fetched" };
    }
  }

  // function getColour(id) {
  //   if (colourFetched) {
  //     return find(coloursList, function (item) {
  //           return item.id === id;
  //     });
  //   }

  //   if (colourError) {
  //     return { error: "Colours could not be fetched" };
  //   }
  // }

  function getTexture(id) {
    if (textureFetched) {
      let texture;
      texturesList.map((item) => {
        if (item.id === id) {
          texture = item;
        }
      });
      return texture;
    }

    if (textureError) {
      return { error: "Textures could not be fetched" };
    }
  }

  function getReaction(id) {
    if (adverseFetched) {
      return find(adverseList, function (item) {
        return item.id === id;
      });
    }
    if (adverseError) {
      return { error: "Adverse list could not be fetched" };
    }
  }

  function getAdverseList() {
    if (adverseFetched) {
      return adverseList;
    }
    if (adverseError) {
      return null;
    }
  }

  return (
    <ParamsContext.Provider
      value={{ getColour, getReaction, getAdverseList, getTexture }}
    >
      {props.children}
    </ParamsContext.Provider>
  );
}

export const useParamsContext = () => {
  const { getColour, getReaction, getAdverseList, getTexture } =
    useContext(ParamsContext);
  return { getColour, getReaction, getAdverseList, getTexture };
};
