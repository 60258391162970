import { render } from "@testing-library/react";
import React from "react";
import { Stepper } from "react-form-stepper";

export function CardA({
  icon = "",
  title = "",
  subtitle = "",
  subtitle2 = "",
  help = "",
  children,
  steps,
  step = "",
}) {
  return (
    <div className='shadow card'>
      <div className='card-body'>
        {icon !== "" && <i className={"float-end mt-1 " + icon}></i>}
        <h5 className='card-title w-75'>
          {title}
          {help !== "" && (
            <span className='ms-2' style={{ fontSize: "1rem" }}>
              <i
                className='far fa-question-circle text-primary'
                data-toggle='tooltip'
                data-placement='top'
                title={help}
                style={{ cursor: "pointer" }}
              ></i>
            </span>
          )}
          {/* {step !== "" && (
            <span style={{ float: "right" }}>
              <Stepper
                steps={steps}
                activeStep={step}
                // connectorStyleConfig={{stepSize:'3em'}} doesn't seem to be working atm
                styleConfig={{
                  size: "1.3em",
                  activeBgColor: "#007bff",
                  completedBgColor: "#0061c9",
                  shape: "circle",
                  color: "#fff",
                }}
                style={{ padding: "5px" }}
              />
            </span>
          )} */}
        </h5>

        {subtitle2 ? (
          <>
            <h6 className='card-subtitle mb-1 text-muted'>{subtitle}</h6>
            <h6 className='card-subtitle mb-4 text-muted fst-italic'>
              {subtitle2}
            </h6>
          </>
        ) : (
          <h6 className='card-subtitle mb-4 text-muted'>{subtitle}</h6>
        )}
        {children}
      </div>
    </div>
  );
}

// CardA.defaultProps = {
//   icon: "",
//   title: "",
//   subtitle: "",
//   help: "",
//   step: "",
// };

export function CardB({
  title = "",
  subtitle = "",
  img = "",
  description = "",
  selected,
  updateTexture,
  id,
}) {
  let imgSrc = require("../../images/".concat(img));
  return (
    <button
      className={selected ? "card border-3 border-dark" : "card"}
      style={
        selected && title === "Other"
          ? {
              width: "345px",
              height: "60px",
              margin: "2px",
              transition: "width 1s",
            }
          : {
              width: "170px",
              height: "60px",
              margin: "2px",
              transition: "width 1s",
            }
      }
      onClick={() => {
        updateTexture(title, id, img);
      }}
    >
      <div className='card-body d-flex p-1 justify-content-space-between w-100'>
        <img
          src={imgSrc}
          width={50}
          height={50}
          // className='card-img-bottom'
          alt={title}
        />
        <label
          className={
            selected && title === "Other"
              ? "card-text align-self-center m-1"
              : "card-text align-self-center m-1 w-100"
          }
          style={{ fontSize: "14px" }}
        >
          {title}
        </label>
        {selected && title === "Other" ? (
          // <div className='w-100 d-inline-flex flex-column'>
          <input
            type='text'
            className='form-control m-1 w-100'
            placeholder='Please specify (required)'
            defaultValue={description}
            aria-label='Other'
            aria-describedby='basic-addon2'
            style={{ fontSize: "12px" }}
            maxLength={"30"}
            onChange={(e) => updateTexture(title, id, img, e.target.value)}
          />
        ) : // {/* <small>20 characters max.</small> */}
        // </div>
        null}
      </div>
    </button>
  );
}
export function CardC({ data }) {
  if (data === undefined || Object.keys(data).length === 0) {
    return <p>n/a</p>;
  } else {
    const { label, img, description } = data;
    let imgSrc = require("../../images/".concat(img));
    return (
      <div className='d-flex'>
        <img
          src={imgSrc}
          width={30}
          height={30}
          // className='card-img-bottom'
          alt={label}
        />
        <p className='card-text align-self-center ms-2 text-wrap text-break'>
          {data.label === "Other" && data.description
            ? `${label} - ${description}`
            : label}
        </p>
        {/* <h6 className='card-subtitle mb-4 text-muted'>{subtitle}</h6> */}
      </div>
    );
  }
}

export function CardD({
  icon = "",
  title = "",
  subtitle = "",
  subtitle2 = "",
  help = "",
  spacing = "",
  buttonTitle = "",
  buttonFunction = () => {},
  buttonStyle = "",
  children,
}) {
  return (
    <div className='shadow card'>
      <div className='card-body'>
        {icon !== "" && <i className={"float-end mt-1 " + icon}></i>}
        <div className='d-flex flex-row'>
          <div className={spacing}>
            <h5 className='card-title w-75'>
              {title}
              {help !== "" && (
                <span className='ms-2' style={{ fontSize: "1rem" }}>
                  <i
                    className='far fa-question-circle text-primary'
                    data-toggle='tooltip'
                    data-placement='top'
                    title={help}
                    style={{ cursor: "pointer" }}
                  ></i>
                </span>
              )}
            </h5>

            {subtitle2 ? (
              <>
                <h6 className='card-subtitle mb-1 text-muted'>{subtitle}</h6>
                <h6 className='card-subtitle mb-4 text-muted fst-italic'>
                  {subtitle2}
                </h6>
              </>
            ) : (
              <h6 className='card-subtitle mb-4 text-muted'>{subtitle}</h6>
            )}
          </div>
          <div className={spacing}>
            <button
              type='button'
              className={"btn mx-auto w-25 float-end " + buttonStyle}
              onClick={() => {
                buttonFunction();
              }}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
