import React, { useState, createContext, useContext } from "react";

const SampleContext = createContext();

export function SampleProvider(props) {
  const [sample, setSample] = useState({
    tested: "",
    presumed: "",
    presumedSubDescription: "",
    colour: [],
    texture: [],
    bulk: [],
    trace: [],
  });

  const [wasatchSample, setWasatchSample] = useState({
    presumed: "",
    presumedSubDescription: "",
    colour: [],
    texture: [],
    bulk: [],
    trace: [],
  });

  //
  const [flow, setFlow] = useState("");
  const [bulks, setBulk] = useState();
  const [traces, setTrace] = useState();
  const [device, setDevice] = useState();

  const setCalibrationDevice = (calibrationDevice) => {
    setDevice(calibrationDevice);
  };

  const setTested = (flag) => {
    setSample({ ...sample, tested: flag });
  };

  const setPresumedSubstance = (substance, description) => {
    if (flow === "wasatch") {
      setWasatchSample({
        ...wasatchSample,
        presumed: substance,
        presumedSubDescription: description,
      });
    } else {
      setSample({
        ...sample,
        presumed: substance,
        presumedSubDescription: description,
      });
    }
  };

  const setColour = (colour) => {
    if (flow === "wasatch") {
      setWasatchSample({ ...wasatchSample, colour: colour });
    } else {
      setSample({ ...sample, colour: colour });
    }
  };

  const setTexture = (texture) => {
    if (flow === "wasatch") {
      setWasatchSample({ ...wasatchSample, texture: texture });
    } else {
      setSample({ ...sample, texture: texture });
    }
  };

  const initBulk = (sequences) => {
    setBulk(
      sequences.map((item) => {
        return {
          date: item.date,
          findings: item.findings,
          deviceId: item.deviceId,
          sequenceId: item.sequenceId,
          library: item.library,
        };
      })
    );
  };

  const initTrace = (sequences) => {
    setTrace(
      sequences.map((item) => {
        return {
          date: item.date,
          findings: item.findings,
          deviceId: item.deviceId,
          sequenceId: item.sequenceId,
          library: item.library,
        };
      })
    );
  };

  const initWasatchBulk = (sequences) => {
    setWasatchSample({ ...wasatchSample, bulk: sequences });
  };

  const initWasatchTrace = (sequences) => {
    setWasatchSample({ ...wasatchSample, trace: sequences });
  };

  const initWasatchBackground = (sequences) => {
    setWasatchSample({ ...wasatchSample, background: sequences });
  };

  // checks that newSequence is not in current sample bulk list, and adds it to list in sample state
  // similar to addTrace()
  const addBulk = (newSequence) => {
    if (
      sample.bulk.indexOf(
        (sequence) => sequence.sequenceId === newSequence.sequenceId
      ) === -1
    )
      setSample({ ...sample, bulk: [...sample.bulk, newSequence] });
  };

  // removes sequence from bulk list using sequenceId
  const removeBulk = (oldSequenceId) => {
    setSample({
      ...sample,
      bulk: [
        ...sample.bulk.filter(
          (sequence) => sequence.sequenceId !== oldSequenceId
        ),
      ],
    });
  };

  // see addBulk()
  const addTrace = (newSequence) => {
    if (
      sample.trace.indexOf(
        (sequence) => sequence.sequenceId === newSequence.sequenceId
      ) === -1
    )
      setSample({ ...sample, trace: [...sample.trace, newSequence] });
  };

  // removes sequence from trace list using sequenceId
  const removeTrace = (oldSequenceId) => {
    setSample({
      ...sample,
      trace: [
        ...sample.trace.filter(
          (sequence) => sequence.sequenceId !== oldSequenceId
        ),
      ],
    });
  };

  // reset all data for new sample
  const reset = () => {
    setSample({
      tested: "",
      presumed: "",
      colour: "",
      bulk: [],
      trace: [],
    });

    setWasatchSample({
      presumed: "",
      colour: [],
      bulk: [],
      trace: [],
    });
  };

  return (
    <SampleContext.Provider
      value={{
        sample,
        wasatchSample,
        device,
        setCalibrationDevice,
        setTested,
        setPresumedSubstance,
        setColour,
        setTexture,
        bulks,
        traces,
        initBulk,
        initTrace,
        initWasatchBulk,
        initWasatchTrace,
        initWasatchBackground,
        addBulk,
        removeBulk,
        addTrace,
        removeTrace,
        reset,
        flow,
        setFlow,
      }}
    >
      {props.children}
    </SampleContext.Provider>
  );
}

export const useSampleContext = () => {
  const {
    sample,
    wasatchSample,
    device,
    setCalibrationDevice,
    setTested,
    setPresumedSubstance,
    setColour,
    setTexture,
    bulks,
    traces,
    initBulk,
    initTrace,
    initWasatchBulk,
    initWasatchTrace,
    initWasatchBackground,
    addBulk,
    removeBulk,
    addTrace,
    removeTrace,
    reset,
    flow,
    setFlow,
  } = useContext(SampleContext);
  return {
    sample,
    wasatchSample,
    device,
    setCalibrationDevice,
    setTested,
    setPresumedSubstance,
    setColour,
    setTexture,
    bulks,
    traces,
    initBulk,
    initTrace,
    initWasatchBulk,
    initWasatchTrace,
    initWasatchBackground,
    addBulk,
    removeBulk,
    addTrace,
    removeTrace,
    reset,
    flow,
    setFlow,
  };
};
