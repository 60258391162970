import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userActions } from "../actions/auth";
import { history } from "../helpers/history";

// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useUserSettings, useUserId } from "../api/user";
import spectraLogo from "../static/img/Spectra Plasmonics logo.webp";
//Elements and CSS from AntDesign

import "../styles/Navbar.css";
import { Link } from "react-router-dom";
// import Login from './Login'

import DropdownMenu from "./navbar/DropdownMenu";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const Navbar = ({ authenticated }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const { data: settings } = useUserSettings();
  const {
    data: userId,
    isLoading: userIdIsLoading,
    isError: userIdIsError,
  } = useUserId();

  useEffect(() => {
    // Check browser storage for authentication status
    const storedAuthStatus = localStorage.getItem("isAuthenticated");
    if (storedAuthStatus === "true") {
      // If authenticated, dispatch appropriate action
      dispatch(userActions.loginSuccess());
    }
  }, [dispatch]);

  return (
    <nav
      id='mainNav'
      className='navbar navbar-expand-lg navbar-light bg-light shadow fixed-top'
      style={{ borderBottom: "0.3rem solid #e3803b" }}
    >
      <div className='container-fluid '>
        <a className='navbar-brand' href='/'>
          <img
            alt='Spectra Plasmonics Logo'
            src={spectraLogo}
            style={{ maxWidth: "20rem" }}
          />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarResponsive'
          aria-controls='navbarResponsive'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarResponsive'
        >
          {userIdIsLoading && (
            <div className='spinner-border' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          )}
          {userIdIsError && (
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <Link className='nav-link' to='/login'>
                  Log in
                </Link>
              </li>
            </ul>
          )}

          {userId && (
            <ul className='navbar-nav ms-auto'>
              <DropdownMenu />
              <li className='nav-item m-auto'>
                <Link className='nav-link' to='/'>
                  Stats
                </Link>
              </li>
              <li className='nav-item m-auto'>
                <Link to='/recent' className={"nav-link"}>
                  Recent Samples
                </Link>
              </li>
              <li className='nav-item m-auto'>
                <button
                  className={"btn btn-primary btn-md my-auto ms-2"}
                  onClick={() => history.push("/new")} //forces page reload
                >
                  New Sample
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
