import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

// contexts
import { useSampleContext } from "../../../contexts";
import { useReachbackContext } from "../../../contexts/ReachbackContext";

import styleDate from "../../../util/dateStyler";

// components
import { CardA, CardC } from "../../ui/Cards";
import { ColourCircleB } from "../../ui/ColourCircle";
import { SequenceE, SequenceF } from "../../ui/Sequence";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Line } from "react-chartjs-2";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const WasatchWizardSummary = ({
  step,
  steps,
  changeStep,
  pageID,
  bulk,
  trace,
}) => {
  const { wasatchSample, sample, flow, reset } = useSampleContext();
  const { submittedErrorsT, submittedErrorsB } = useReachbackContext();

  const [submit, setSubmit] = useState("");
  const [sampleID, setSampleID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [loadingPercent, setLoadingPercent] = useState("0");
  const [startTime, setStartTime] = useState();
  const fakeLoading = useRef();
  const history = useHistory();

  async function handleSubmit() {
    const summary = {
      // customerId: sample.customerId,
      // locationId: sample.locationId,
      // deviceId: sample.deviceId,
      // description: sample.description,
      presumedSubstance: wasatchSample.presumed,
      presumedSubDescription: wasatchSample.presumedSubDescription,
      // tests: wasatchSample.trace,
      // notes: sample.notes,
      // categories: sample.categories,
      colour: wasatchSample.colour[1],
      texture: {
        id: wasatchSample.texture.id,
        description: wasatchSample.texture.description,
      },
      reachback: wasatchSample.reachback,
      // sampleId: sample.sampleId,
      // createdBy: sample.createdBy,
      backgroundScan: wasatchSample.background,
      traceScans: (wasatchSample.bulk || []).concat(wasatchSample.trace || []),
      flow: flow,
      reachback: submittedErrorsT || submittedErrorsB ? true : false,
      isErrorT: submittedErrorsT ? true : false,
      isErrorB: submittedErrorsB ? true : false,
      reachbackComplete:
        submittedErrorsT || submittedErrorsB ? "inProgress" : "N/A",
    };

    await axios
      .post("/companion/new-sample/submit-sample", summary)
      .then(function (res) {
        setSampleID(res.data);
        setSubmit("success");
      })
      .catch((error) => handleError(error));
    // submitSample(summary);
    // setSubmit(true);
  }

  function handleError(error) {
    console.log(error);
    setSubmit("error");
  }
  console.log(
    "Submitted Errors:",
    // submittedErrors,
    submittedErrorsT,
    submittedErrorsB
  );
  /**
   * Reset the summary page (remove the loading bar if necessary) when refreshing
   * the page.
   */
  useEffect(() => {
    if (step === pageID) {
      setLoadingText("");
      setLoadingPercent(0);
    }
  }, [step]);

  const handleReset = () => {
    reset();
  };

  /**
   * Fake function for the loading bar. Recursively calls itself to increase
   * loading bar percentage. Will load, with a degree of randomness. Increases
   * the percentage until just before 100%, usually in around 2-3sec.
   */
  function fakeLoadingBar(percent) {
    // Catch any calls outside the bounds (0 and 100 inclusive)
    if (percent < 0) {
      console.error("Negative percent given to loading bar.");
      updateLoadingBar(0);
      return;
    } else if (percent > 100) {
      console.error("Over 100 percent given to loading bar.");
      updateLoadingBar(100);
      return;
    }

    updateLoadingBar(percent);

    // Increase the percent by 15-34%, and wait between 0.4 & 0.8 sec
    let randPercent = percent + 15 + Math.floor(Math.random() * 20);

    // If the loading bar is about to finish, stop it (wait for server response)
    if (randPercent >= 100) {
      return;
    }
    fakeLoading.current = setTimeout(
      () => fakeLoadingBar(randPercent),
      400 + Math.floor(Math.random() * 400)
    );
  }

  /**
   * Stops the fake loading process.
   */
  function stopFakeLoadingBar() {
    setLoading(false);
    if (fakeLoading.current) {
      clearTimeout(fakeLoading.current);
    }
  }

  /**
   * Sets the loading message to fake details based on the percentage given.
   * @param {*} percent An int between 0 and 100 inclusive.
   */
  function updateLoadingBar(percent) {
    // console.log(`Setting percent to ${percent}`);
    if (percent < 0) {
      percent = 0;
      setLoadingText("Something went wrong, please try again ...");
    } else if (percent < 100) {
      setLoadingText("Sending sample results...");
    } else {
      percent = 100;
      setLoadingText(
        "Sample submitted! You will be redirected in 3 seconds..."
      );
    }
    setLoadingPercent(percent);
  }

  useEffect(() => {
    // If the sample submission failed, reset the loading bar
    if (submit === "error") {
      console.log(
        `Sample submission failed in ${
          (new Date().getTime() - startTime) / 1000
        } sec.`
      );
      stopFakeLoadingBar();
      updateLoadingBar(-1);
      setSubmit("");
    }

    // If the sumission was a success and the sampleID was set, prepare to redirect and finished the
    // loading bar
    if (submit === "success" && sampleID) {
      console.log(
        `Sample submission took ${
          (new Date().getTime() - startTime) / 1000
        } sec.`
      );
      stopFakeLoadingBar();
      updateLoadingBar(100);
      let timer = setTimeout(() => {
        setSubmit("");
        history.push(`/sample/${sampleID}`);
        handleReset();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [submit, sampleID, history]);

  if (step !== pageID) {
    return null;
  }

  const renderChart = (data) => {
    // Round the wavenumber_plot values to the nearest 100s
    const roundedWavenumbers = data.wavenumber_plot.map(
      (value) => Math.round(value / 100) * 100
    );

    const spectraChartData = {
      labels: roundedWavenumbers,
      datasets: [
        {
          label: "Spectra Scan",
          data: data.processed_data_plot,
          fill: false,
          borderColor: "#0093EA",
          tension: 0.1,
        },
      ],
    };

    const graphOptions = {
      scales: {
        x: {
          title: {
            display: true,
            text: "Raman shift (cm^-1)",
          },
        },
        y: {
          title: {
            display: true,
            text: "Intensity (a.u.)",
          },
        },
      },
      elements: {
        point: {
          radius: 1,
        },
      },
      plugins: {
        legend: {
          align: "end",
          display: true,
          labels: {
            boxWidth: 10,
          },
        },
      },
      maintainAspectRatio: false,
      width: "100%",
    };

    return (
      <div style={{ width: "450px", height: "250px" }}>
        <Line data={spectraChartData} options={graphOptions} />
      </div>
    );
  };

  return (
    <div>
      <CardA
        title='Sample Summary'
        subtitle='Please confirm information before submitting for analysis.'
        steps={steps}
        step={step - 1}
      >
        <div className='ms-4 my-5'>
          <div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-4'>
                    <h6>Presumed Substance</h6>
                    <p>
                      {wasatchSample.presumed === "Other" &&
                      wasatchSample.presumedSubDescription
                        ? `${wasatchSample.presumed} - ${wasatchSample.presumedSubDescription}`
                        : wasatchSample.presumed}
                    </p>
                  </div>
                  <div className='col-4'>
                    <h6>Sample Colour</h6>
                    <div>
                      <ColourCircleB
                        colour={wasatchSample.colour[0]}
                        label={wasatchSample.colour[2]}
                      />
                    </div>
                  </div>
                  <div className='col-4'>
                    <h6>Sample Texture</h6>
                    <div>
                      <CardC data={wasatchSample.texture} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='d-flex align-items-center'>
                      <h6 style={{ marginRight: "10px" }}>Bulk Scan</h6>
                      {submittedErrorsB ? (
                        <div className='d-flex align-items-center'>
                          <i
                            className='bi bi-exclamation-triangle-fill'
                            style={{
                              fontSize: "large",
                              color: "red",
                              marginRight: "10px",
                              marginBottom: "2px",
                            }}
                          ></i>
                          <span style={{ color: "red" }}>
                            Sample will be submitted for reachback service
                          </span>
                        </div>
                      ) : null}
                    </div>

                    {!wasatchSample.bulk || wasatchSample.bulk.length === 0 ? (
                      <p className='card-text'>Skipped</p>
                    ) : (
                      <div className='row gy-4'>
                        {wasatchSample.bulk &&
                          wasatchSample.bulk.sort(
                            (seq1, seq2) => seq1.scanId - seq2.scanId
                          ) &&
                          wasatchSample.bulk.map((item, index) => {
                            return (
                              <div key={index} className='col-lg-6'>
                                <SequenceF
                                  deviceID={item.deviceSerial}
                                  sequenceId={item.scanId}
                                  date={styleDate(item.date)}
                                />
                                {renderChart(item)}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div className='col-6'>
                    <div className='d-flex align-items-center'>
                      <h6 style={{ marginRight: "10px" }}>Trace Scan</h6>
                      {submittedErrorsT ? (
                        <div className='d-flex align-items-center'>
                          <i
                            className='bi bi-exclamation-triangle-fill'
                            style={{
                              fontSize: "large",
                              color: "red",
                              marginRight: "10px",
                              marginBottom: "2px",
                            }}
                          ></i>
                          <span style={{ color: "red" }}>
                            Sample will be submitted for reachback service
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {!wasatchSample.trace ||
                    wasatchSample.trace.length === 0 ? (
                      <p className='card-text'>Skipped</p>
                    ) : (
                      <div className='row gy-4'>
                        {wasatchSample.trace &&
                          wasatchSample.trace.sort(
                            (seq1, seq2) => seq1.scanId - seq2.scanId
                          ) &&
                          wasatchSample.trace.map((item, index) => {
                            return (
                              <div key={index} className='col-lg-6'>
                                <SequenceF
                                  deviceID={item.deviceSerial}
                                  sequenceId={item.scanId}
                                  date={styleDate(item.date)}
                                />
                                {renderChart(item)}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            {(!wasatchSample.bulk || wasatchSample.bulk.length === 0) &&
              (!wasatchSample.trace || wasatchSample.trace.length === 0) && (
                <div className='row'>
                  <div className='d-flex align-items-center'>
                    <i
                      className='bi bi-exclamation-triangle-fill'
                      style={{
                        fontSize: "large",
                        color: "red",
                        marginRight: "10px",
                        marginBottom: "2px",
                      }}
                    ></i>
                    <span style={{ color: "red" }}>
                      Error: No scans selected. Please go back and select at
                      least one scan.
                    </span>
                  </div>
                </div>
              )}
            {/* <div className="card">
              <div className="card-body">
                <h5 className="card-title text-primary">Additional Information</h5>
                <h6>Tested</h6>
                <p className="card-text">
                  {(!wasatchSample.tested || wasatchSample.tested === "") ? "N/A" : `${wasatchSample.tested}`}
                </p>
              </div>
            </div> */}
          </div>
        </div>
        {
          <div className='row'>
            <button
              type='button'
              className='col-4 btn btn-secondary mx-auto w-30'
              disabled={submit || loading}
              onClick={() => changeStep("back")}
            >
              Back
            </button>
            <button
              type='button'
              className='col-4 btn btn-success mx-auto w-30'
              disabled={
                submit ||
                loading ||
                ((!wasatchSample.bulk || wasatchSample.bulk.length === 0) &&
                  (!wasatchSample.trace || wasatchSample.trace.length === 0))
              }
              onClick={() => {
                setLoading(true);
                setStartTime(new Date().getTime());
                fakeLoadingBar(0);
                handleSubmit();
              }}
            >
              Submit for analysis
            </button>
          </div>
        }
        {loadingText && (
          <div>
            <br />
            <p className='text-center'>
              <strong>{loadingText}</strong>
            </p>
            <div className='progress w-75 mx-auto'>
              <div
                className='progress-bar progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: loadingPercent + "%" }}
              ></div>
            </div>
          </div>
        )}
      </CardA>
    </div>
  );
};

export default WasatchWizardSummary;
