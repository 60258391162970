import React, { useState, useEffect } from "react";

// pages for Wizard
import WizardCalibrateScan from "./CalibrationFlow/WizardCalibrateScan";
import WizardCalibrateSuccess from "./CalibrationFlow/WizardCalibrateSuccess";
import WizardCalibrateFail from "./CalibrationFlow/WizardCalibrateFail";

const WizardCalibrate = ({
  step,
  isCalibrate,
  setIsCalibrate,
  steps,
  changeStep,
  pageID,
  setShowToast,
}) => {
  // step tracker, this state is used to show corresponding wizard page
  const [calibrationStep, setCalibrationStep] = useState(0);
  const [stepAction, setStepAction] = useState("");

  //
  const [submitStatus, setSubmitStatus] = useState(true);

  //
  const wizardPages = {
    WizardCalibrateScan: 0,
    WizardCalibrateFail: 1,
    WizardCalibrateSuccess: 2,
  };

  // Labels for stepper, currently set to no labels
  const wizardSteps = [{ label: "" }, { label: "" }, { label: "" }];

  // function to update the step
  // this will be passed into each Wizard Component
  // and will appropriately udpate the current step
  const changeCalibrationStep = (value) => {
    setStepAction(value);
    console.log(`step: ${calibrationStep} ${value}`);

    if (value === "forward") {
      if (calibrationStep < Object.keys(wizardPages).length - 1) {
        setCalibrationStep(calibrationStep + 1);
      } else {
        // If at final calibration (success) screen, move to next step
        setCalibrationStep(wizardPages.WizardCalibrateScan);
        changeStep("continue");
      }
    }
    if (value === "back" && calibrationStep > 0) {
      setCalibrationStep(calibrationStep - 1);
    }
    if (
      value === "success"
      // && calibrationStep < Object.keys(wizardPages).length
    ) {
      // console.log(
      //   "Changing to success page (" +
      //     wizardPages.WizardCalibrateSuccess +
      //     ")..."
      // );
      // setCalibrationStep(wizardPages.WizardCalibrateSuccess);
      setCalibrationStep(wizardPages.WizardCalibrateScan);
      changeStep("continue");
      setIsCalibrate(false);
      setShowToast(true);
    }
    if (value === "fail" && calibrationStep < Object.keys(wizardPages).length) {
      console.log(
        "Changing to fail page (" + wizardPages.WizardCalibrateFail + ")..."
      );
      setCalibrationStep(wizardPages.WizardCalibrateFail);
    }
    if (value === "scan" && calibrationStep < Object.keys(wizardPages).length) {
      console.log(
        "Changing to scan page (" + wizardPages.WizardCalibrateScan + ")..."
      );
      setCalibrationStep(wizardPages.WizardCalibrateScan);
    }
    if (value === "return") {
      setCalibrationStep(wizardPages.WizardCalibrateScan); // In case user tries to calibrate another
      changeStep("return");
      setIsCalibrate(false);
    }
  };

  if (!isCalibrate) {
    return null;
  }
  return (
    <div className='container'>
      <div className=''>
        <WizardCalibrateScan
          step={calibrationStep}
          steps={wizardSteps}
          changeStep={changeCalibrationStep}
          pageID={wizardPages.WizardCalibrateScan}
        />
        <WizardCalibrateFail
          step={calibrationStep}
          steps={wizardSteps}
          changeStep={changeCalibrationStep}
          pageID={wizardPages.WizardCalibrateFail}
        />
        <WizardCalibrateSuccess
          step={calibrationStep}
          steps={wizardSteps}
          setIsCalibrate={setIsCalibrate}
          changeStep={changeCalibrationStep}
          pageID={wizardPages.WizardCalibrateSuccess}
        />
      </div>
      {!submitStatus && (
        <div className='alert alert-danger' role='alert'>
          Something went wrong, please try again!
        </div>
      )}
    </div>
  );
};

export default WizardCalibrate;
