const SampleCons = {
  PRESUMED_SUBSTANCES: [
    "Carfentanil",
    "Cocaine",
    "Fentanyl",
    // "Heroin",
    "Isotonitazene",
    // removed Ketamine
    "MDMA",
    "Methamphetamine",
    "Other",
  ],
  TRACE_SUPPORTED: [
    "Carfentanil",
    "Cocaine",
    "Fentanyl",
    "Isotonitazene",
    "Methamphetamine",
    "Other",
  ],
  NEW_PRESUMED_SUBSTANCES: [
    {
      label: "Opiods",
      drugs: [
        "Carfentanil",
        "Fentalog (Fentanyl, Fluorfentanyl, etc.)",
        "Heroin",
        "Nitazene",
      ],
    },
    {
      label: "Stimulants",
      drugs: ["Cocaine", "Methamphetamine", "MDMA/MDA"],
    },
    {
      label: "Depressants",
      drugs: ["Benzodiazepines (Bromazalom, Flubromazolam, Clonazepam, etc.)"],
    },
    {
      label: "Dissociative Drugs",
      drugs: ["Ketamine", "Phencyclidine (PCP)", "Dextromethorphan (DXM)"],
    },
    {
      label: "Prescription Medications", //update any changes to this list in Body.js too
      drugs: [
        "Adderall®",
        "Ativan®",
        "Dilaudid®",
        "Klonopin®",
        "OxyContin®",
        "Ritalin®",
        "Valium®",
        "Vyvanse®",
        "Xanax®",
      ],
    },
    {
      label: "Other Compounds",
      drugs: ["Xylazine", "Other"],
    },
  ],
};

export default SampleCons;
