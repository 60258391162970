import { useState } from "react";
import OrderFormModal from "./ui/OrderFormModal";
import { useUserId } from "../api/user";

const Footer = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    data: userId,
    isLoading: userIdIsLoading,
    isError: userIdIsError,
  } = useUserId();

  return (
    <footer className='footer bg-light py-3'>
      <div className='container'>
        <div className='small text-center text-muted'>
          Need any help?{" "}
          <a
            href='https://www.spectraplasmonics.com/amplifi-id-faq'
            target='_blank'
          >
            Click here to access our FAQ
          </a>
        </div>
        {userId && (
          <div className='small text-center text-muted'>
            Do you need more test kits?{" "}
            <a
              href='https://www.spectraplasmonics.com/amplifi-id-faq'
              target='_blank'
              onClick={(event) => {
                event.preventDefault();
                setModalVisible(true);
              }}
            >
              Click here to order test kits
            </a>
          </div>
        )}
        <div className='small text-center text-muted'>
          Copyright © - 2024 Spectra Plasmonics Inc.
        </div>
      </div>
      <OrderFormModal
        showModal={modalVisible}
        handleClose={() => setModalVisible(false)}
      ></OrderFormModal>
    </footer>
  );
};
export default Footer;
