import React, { useState, useEffect } from "react";
import { Stepper } from "react-form-stepper";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

// get sequence from query API
import { useDefaultDeviceManufacturer, useSequence } from "./../api";

// contexts
import { useSampleContext } from "./../contexts";
// import { ReachbackProvider } from "../contexts/ReachbackContext";

// components
import Footer from "./../components/Footer";
import Navbar from "./../components/Navbar";

// pages for Wizard
// import WizardStart from "./../components/WizardPages/WizardStart";
// import WizardDevice from "../components/WizardPages/WizardDevice";
import WizardDeviceSelection from "../components/WizardPages/WizardDeviceSelection";
import WizardCalibrate from "../components/WizardPages/WizardCalibrate";
import WizardSampleInfo from "../components/WizardPages/WizardSampleInfo";
// import WizardTested from "./../components/WizardPages/WizardTested";
// import WizardPresumed from "./../components/WizardPages/WizardPresumed";
// import WizardColour from "./../components/WizardPages/WizardColour";
import WizardBulk from "./../components/WizardPages/Wasatch/WizardBulk";
// import WasatchWizardBackground from "../components/WizardPages/Wasatch/WasatchWizardBackground";
import WasatchWizardTrace from "../components/WizardPages/Wasatch/WasatchWizardTrace";
import WizardTrace from "./../components/WizardPages/WizardTrace";
import WizardSummary from "./../components/WizardPages/WizardSummary";
import WasatchWizardSummary from "../components/WizardPages/Wasatch/WasatchWizardSummary";

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const NewSample = () => {
  // useSequence api fetches data from backend with library name
  const { data: bulk, isFetching: bulkFetching } = useSequence("Narcotics");
  const { data: trace, isFetching: traceFetching } = useSequence("Amplifi");

  const { initBulk, initTrace, setFlow } = useSampleContext();

  // step tracker, this state is used to show corresponding wizard page
  const [step, setStep] = useState(0);
  const [stepAction, setStepAction] = useState("");

  //booleans for if calibration is needed and to show the successfull calibration toast
  const [isCalibrate, setIsCalibrate] = useState(false);
  const [showToast, setShowToast] = useState(false);

  //
  const [submitStatus, setSubmitStatus] = useState(true);

  // fetch default device manufacturer
  const { data: defaultDevice, isFetching: defaultDeviceFetching } =
    useDefaultDeviceManufacturer();

  // update sample context with default device
  useEffect(() => {
    if (!defaultDeviceFetching) {
      setFlow(defaultDevice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDevice, defaultDeviceFetching]);

  // List of wizard pages
  const wizardPagesList = [
    // "WizardStart",
    // "WizardDevice",
    "WizardDeviceSelection",
    "WizardSampleInfo",
    // "WizardPresumed",
    // "WizardTested",
    // "WizardColour",
    "WizardBulk",
    // "WasatchWizardBackground",
    "WizardTrace",
    "WasatchWizardTrace",
    "WizardSummary",
    "WasatchWizardSummary",
  ];

  // Generate flow based on the customer default device manufacturer
  const generateFlow = (device) => {
    let disabledpages;
    switch (device) {
      case "wasatch":
        disabledpages = ["WizardDevice", "WizardTrace", "WizardSummary"];
        break;
      case "anton paar":
        disabledpages = [
          "WizardDevice",
          "WizardDeviceSelection",
          "WasatchWizardTrace",
          "WasatchWizardSummary",
          "WasatchWizardBackground",
        ];
        break;
      default:
        disabledpages = [];
    }
    return {
      ...wizardPagesList
        .filter((p) => !disabledpages.includes(p))
        .reduce((a, v, index) => ({ ...a, [v]: index }), {}),
      WizardCalibrate: "calibrate",
    };
  };

  // Genereate Wizard Pages
  const wizardPages = generateFlow(defaultDevice);
  console.log(wizardPages);

  // Labels for stepper, currently set to no labels
  let wizardSteps = [];
  for (let i = 0; i < Object.keys(wizardPages).length - 1; i++) {
    wizardSteps.push({ label: "" });
  }

  // update bulk sequence state on query update
  useEffect(() => {
    if (!bulkFetching) {
      setData(bulk.sequences, "bulk-burst");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkFetching, bulk]);

  //update trace seuqecs on query update
  useEffect(() => {
    if (!traceFetching) {
      setData(trace.sequences, "trace-burst");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traceFetching, trace]);

  // set data function to update the state of either bulk or trace sequences
  const setData = (sequence, type) => {
    if (type === "bulk-burst") {
      initBulk(sequence);
    }
    if (type === "trace-burst") {
      initTrace(sequence);
    }
  };

  // show sample on update in console for debug purposes
  // useEffect(() => {
  //   console.log(sample);
  // }, [sample]);

  // function to update the step
  // this will be passed into each Wizard Component
  // and will appropriately udpate the current step
  const changeStep = (value) => {
    setStepAction(value);
    console.log("step:", step, value);

    if (value === "calibrate") {
      // setStep("calibrate");
      setIsCalibrate(true);
    }
    if (value === "return") {
      setStep(wizardPages.WizardDeviceSelection);
    }
    if (value === "continue") {
      setStep(wizardPages.WizardSampleInfo);
    }
    if (value === "forward" && step < 10) {
      setStep(step + 1);
    }
    if (value === "back" && step > 0) {
      setStep(step - 1);
    }
    if (value === "reset") {
      resetForm();
    }
  };

  async function submitSample(value) {
    // console.log("Sample submitted: ", value);
    const result = await axios
      .post("/companion/new-sample/submit-sample", value)
      .then(function (res) {
        console.log("Submitted data: ", value, ". Res: ", res);
        if (res.status === 200) {
          redirect();
        } else {
          console.log("Failed");
          setSubmitStatus(false);
          setTimeout(() => setSubmitStatus(true), 3000);
        }
      });
    console.log(result);
  }

  function redirect() {}

  const resetForm = () => {
    setStep(0);
  };

  return (
    <div className='d-flex flex-column justify-content-between'>
      <Navbar authenticated={false} />
      <div className='container mt-5 '>
        {step !== "" && (
          <div className='row mt-5 ' style={{ width: "100%" }}>
            <Stepper
              steps={wizardSteps}
              activeStep={step}
              // connectorStyleConfig={{stepSize:'3em'}} doesn't seem to be working atm
              styleConfig={{
                size: "1.5em",
                activeBgColor: "#1CA4EE",
                completedBgColor: "#8ED2F7",
                shape: "circle",
                color: "#fff",
              }}
              style={{ padding: "5px" }}
            />
          </div>
        )}
        <div
          className='row mt-3 justify-content-center'
          style={{ width: "100%" }}
        >
          {/* <WizardStart
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardStart}
          /> */}
          {/* <WizardDevice
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardDevice}
          /> */}
          <WizardDeviceSelection
            step={step}
            steps={wizardSteps}
            isCalibrate={isCalibrate}
            changeStep={changeStep}
            pageID={wizardPages.WizardDeviceSelection}
          />
          <WizardCalibrate
            step={step}
            isCalibrate={isCalibrate}
            setIsCalibrate={setIsCalibrate}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardCalibrate}
            setShowToast={setShowToast}
          />
          <WizardSampleInfo
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardSampleInfo}
          />
          {/* <WizardTested
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardTested}
          />
          <WizardPresumed
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardPresumed}
          />
          <WizardColour
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardColour}
          /> */}
          <WizardBulk
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardBulk}
          />
          {/* <WasatchWizardBackground
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WasatchWizardBackground}
          /> */}
          <WizardTrace
            step={step}
            steps={wizardSteps}
            stepAction={stepAction}
            changeStep={changeStep}
            pageID={wizardPages.WizardTrace}
          />
          <WasatchWizardTrace
            step={step}
            steps={wizardSteps}
            stepAction={stepAction}
            changeStep={changeStep}
            pageID={wizardPages.WasatchWizardTrace}
          />
          <WizardSummary
            step={step}
            steps={wizardSteps}
            changeStep={changeStep}
            pageID={wizardPages.WizardSummary}
            submitSample={submitSample}
          />
          <WasatchWizardSummary
            step={step}
            steps={wizardSteps}
            pageID={wizardPages.WasatchWizardSummary}
            changeStep={changeStep}
            bulk={bulk} // Pass the 'bulk' data as a prop
            trace={trace}
          />
        </div>
        {!submitStatus && (
          <div className='alert alert-danger' role='alert'>
            Something went wrong, please try again!
          </div>
        )}
      </div>
      <div
        aria-live='polite'
        aria-atomic='true'
        className='position-relative'
        style={{ minHeight: "90px" }}
      >
        <ToastContainer position='bottom-end' style={{ zIndex: 1 }}>
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={5000}
            autohide
            bg={"success"}
            animation={true}
            className='d-inline-block'
          >
            <Toast.Header style={{ justifyContent: "space-between" }}>
              <strong>Calibration Successful!</strong>
            </Toast.Header>
            <Toast.Body style={{ color: "var(--bs-light)" }}>
              Device operating correctly and ready to scan.
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>

      <Footer />
    </div>
  );
};

export default NewSample;
